import { builder, Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

Builder.registerComponent(
  dynamic(
    async () =>
      (await import('./lib/components/dashboard/charts/LineChart')).LineChart,
  ),
  {
    name: 'LineChart',
    inputs: [
      {
        name: 'trend',
        type: 'number',
      },
      {
        name: 'values',
        type: 'object',
        hideFromUI: true,
        meta: {
          ts: 'any[]',
        },
        required: true,
      },
      {
        name: 'xValue',
        type: 'string',
      },
      {
        name: 'yValue',
        type: 'string',
        required: true,
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(
    async () =>
      (await import('./lib/components/dashboard/charts/HorizontalStackChart'))
        .HorizontalStackChart,
  ),
  {
    name: 'HorizontalStackChart',
    inputs: [
      {
        name: 'data',
        type: 'object',
        hideFromUI: true,
        meta: {
          ts: 'any[]',
        },
        required: true,
      },
      {
        name: 'groupingAttr',
        type: 'string',
        required: true,
      },
      {
        name: 'thickness',
        type: 'number',
      },
    ],
  },
);
